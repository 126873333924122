<template>
  <v-container id="map-container" fluid tag="section">
    <v-row justify="center">
      <v-col cols="12" md="12">
        <material-card color="primary" full-header class="card-group">
          <template #heading>
            <div class="pa-5 white--text">
              <div class="text-h4 font-weight-bold">
                <v-icon large class="mr-3">
                  mdi-map
                </v-icon>
                Leaflet / OpenStreetMap
              </div>
            </div>
          </template>
          <v-col cols="12" class="pa-5 pt-0">
            <l-map
              style="width:100%;height:700px"
              :zoom="zoom"
              :center="center"
            >
              <l-tile-layer :url="url" :attribution="attribution" />
              <l-marker :lat-lng="markerLatLng" />
            </l-map>
          </v-col>
        </material-card>
      </v-col>
    </v-row>
    <!-- 由於 Indoor Map 寫在 index.html，每個 Component 都要建一個 id="myMap" 的元素，否則 console 會有錯誤訊息，更嚴謹的處理方案待研究。 -->
    <div id="myMap" />
  </v-container>
</template>

<script>
// If you need to reference 'L', such as in 'L.icon', then be sure to
// explicitly import 'leaflet' into your component
// import L from 'leaflet'
// 載入 vue2-leaflet，依照自己需要載入組件
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet'
import { Icon } from 'leaflet'

delete Icon.Default.prototype._getIconUrl
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

export default {
  name: 'MapLeaflet',
  components: {
    LMap,
    LTileLayer,
    LMarker
  },
  data () {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 15,
      center: [51.505, -0.159],
      markerLatLng: [51.504, -0.159]
    }
  },
  mounted () {},
  methods: {}
}
</script>

<style lang="scss">
#myMap {
  display: none;
}
// 原本 z-index 400 太上層<@at-root會干擾側攔選單
.leaflet-pane {
  z-index: 1 !important;
}
.leaflet-top {
  z-index: 2 !important;
}
</style>
